import React, { useState } from "react";
import {
    Card,
    CardMedia,
    CardContent,
    CardActions,
    Typography,
    Button,
    Dialog,
    DialogActions,
    DialogTitle,
    DialogContent,
    Container,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ReactHtmlParser from "react-html-parser";
import { formatUS } from "./../functions/currency";

const ServiceCard = (props) => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);

    const toggleOpen = () => {
        setOpen(!open);
    };
    return (
        <Card className={classes.card}>
            {props.image ? (
                <CardMedia
                    className={classes.image}
                    src={props.image}
                    title={props.imageTitle}
                    component="img"
                />
            ) : (
                ""
            )}
            <CardContent>
                <Typography className={classes.title}>{props.title}</Typography>
                <Typography className={classes.subtitle}>
                    {props.subtitle}
                </Typography>
                {props.price ? (
                    props.salePrice ? (
                        <Container className={classes.priceContainer}>
                            <Typography className={classes.salePrice}>
                                Sale! {formatUS(props.salePrice)}
                            </Typography>
                            <Typography className={classes.priceCrossedOut}>
                                {formatUS(props.price)}
                            </Typography>
                        </Container>
                    ) : (
                        <Container className={classes.priceContainer}>
                            <Typography className={classes.price}>
                                {formatUS(props.price)}
                            </Typography>
                        </Container>
                    )
                ) : (
                    ""
                )}
                {props.priceOverride ? (
                    <Container className={classes.priceContainer}>
                        <Typography className={classes.price}>
                            {ReactHtmlParser(props.priceOverride)}
                        </Typography>
                    </Container>
                ) : (
                    ""
                )}
            </CardContent>
            {props.details ? (
                <div>
                    <CardActions className={classes.actions}>
                        <Button
                            size="small"
                            color="primary"
                            onClick={() => toggleOpen()}
                        >
                            {props.buttonValue
                                ? props.buttonValue
                                : "View Details"}
                        </Button>
                    </CardActions>
                    <Dialog open={open} onClose={toggleOpen}>
                        <DialogTitle>{props.detailTitle}</DialogTitle>
                        <DialogContent>
                            {ReactHtmlParser(props.details)}
                        </DialogContent>
                        <DialogActions>
                            <Button
                                size="small"
                                variant="contained"
                                onClick={() => toggleOpen()}
                            >
                                Close
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
            ) : (
                ""
            )}
        </Card>
    );
};

const useStyles = makeStyles((theme) => ({
    card: {
        width: 350,
        marginTop: 10,
        margin: 10,
        flexBasis: "auto",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
    },
    image: {
        height: 175,
        width: "fit-content",
        margin: "auto",
    },
    title: {
        fontWeight: "bold",
        fontSize: 20,
        textAlign: "center",
    },
    subtitle: {
        fontStyle: "italic",
        fontSize: 16,
        textAlign: "center",
    },
    actions: {
        display: "flex",
        justifyContent: "center",
    },
    priceContainer: {
        display: "flex",
        justifyContent: "space-around",
        alignItems: "center",
        textAlign: 'center'
    },
    priceCrossedOut: {
        textDecoration: "line-through",
        textAlign: 'center'
    },
    salePrice: {
        color: "red",
        fontWeight: "bold",
        textAlign: 'center',
        paddingRight: 20,
    },
}));

export default ServiceCard;
