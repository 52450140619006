import React from "react";
import { Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const PageBanner = (props) => {
    const classes = useStyles();
    return (
        <Container className={classes.container}>
            <img src={props.image} className={classes.image} alt={props.alt} />
        </Container>
    );
};

const useStyles = makeStyles((theme) => ({
    image: {
        height: "100%",
        marginLeft: "50%",
        transform: "translate(-50%, 0)",
    },
    container: {
        width: "100%",
        height: 300,
        padding: 0,
        margin: 0,
        textAlign: "center",
        overflow: "hidden",
    },
}));

export default PageBanner;
