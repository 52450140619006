import React, { useEffect, useState } from "react";
import { Container } from "@material-ui/core";
import TopCard from "../common/TopCard";
import ServiceCard from "../common/ServiceCard";
import { makeStyles } from "@material-ui/core/styles";
import pageBanner from "../images/coaching-banner.jpg";
import PageBanner from "../common/PageBanner";
import AnnouncementCard from "../common/AnnouncementCard";
import { getPageData } from "../api/PageDataAPI";

// const pageData = {
// 	announcement: {
// 		title: "Summer Coaching Special",
// 		text: "This summer we have a back to bowling coaching special! Get an hour lesson with USBC Silver Coach Shawn Lee for only $45! Call to schedule today!",
// 	},
// 	bannerText:
// 		"We offer the latest instruction for beginning to advanced bowlers one-on-one and for teams. Tips and video lessons are posted regularly on our blog, and we offer a selection of books and DVDs for those that enjoy the medium. Sign up for a series of lessons or get a free lesson with the purchase of a new ball. And if you need a quick tip on a specific problem just ask! We'll be glad to help you.",
// 	cards: [
// 		{
// 			title: "Coaching Special!",
// 			details:
// 				"June and July special pricing! Get back into bowling with our discounted coaching rates! 1 hour lesson for $45!",
// 			price: 65,
// 			salePrice: 45,
// 		},
// 		{
// 			title: "Private 1 Hour Lesson",
// 			priceOverride: "$65.00<br />$275.00 for 5",
// 			details:
// 				"Individual lessons are $65/hour and include lineage. Five-lesson packages are available for $275.00.<br /><br />Free half-hour lesson with first ball purchase from TenBack.",
// 		},
// 		{
// 			title: "Team Package",
// 			priceOverride: "Call for Details",
// 			details:
// 				"Team lessons are available! Team bowling isn't just about hitting the pins, it's about teamwork. We work with teams to build them up for the best possible game-play. Please call for pricing and availability.",
// 		},
// 	],
// };

const Coaching = () => {
	const [pageData, setPageData] = useState({});
	const classes = useStyles();

	const retrievePageData = async () => {
		let response = await getPageData("coaching");
		setPageData(response);
	};

	useEffect(() => {
		retrievePageData();
	}, []);

	return pageData ? (
		<Container className={classes.fullPageContainer}>
			<PageBanner image={pageBanner} alt="Maintenance Banner" />
			<Container className={classes.mainContainer} maxWidth="lg">
				{pageData.announcement ? (
					<AnnouncementCard
						title={pageData.announcement.title}
						text={pageData.announcement.text}
					/>
				) : (
					""
				)}
				<TopCard text={pageData.bannerText} />
				<Container className={classes.serviceContainer}>
					{pageData.cards
						? pageData.cards.map((item) => {
								return (
									<ServiceCard
										image={item.image}
										title={item.title}
										price={item.price}
										salePrice={item.salePrice}
										priceOverride={item.priceOverride}
										detailTitle={item.title}
										details={item.details}
									/>
								);
						  })
						: ""}
				</Container>
			</Container>
		</Container>
	) : "Please Wait...";
};

const useStyles = makeStyles((theme) => ({
	serviceContainer: {
		display: "flex",
		justifyContent: "center",
		flexWrap: "wrap",
	},
	fullPageContainer: {
		padding: 0,
		width: "100%",
		margin: "auto",
	},
}));

export default Coaching;
