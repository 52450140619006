import React from "react";
import { Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import pageBanner from "../images/staff-banner.jpg";
import TopCard from "../common/TopCard";
import ServiceCard from "../common/ServiceCard";
import PageBanner from "./../common/PageBanner";
import AnnouncementCard from "./../common/AnnouncementCard";

const pageData = {
    bannerText:
        "Come meet our staff! At TenBack, we pride ourselves on being able to provide you with world-class service every day. We provide our staff with the best training possible to make sure your equipment comes out right every time!",
    services: [
        {
            firstName: "Shawn",
            lastName: "Lee",
            position: "The Boss",
            image: "https://tenbackbowling.com/common/lib/img/staff/shawn-bio-web.jpg",
            bio: "Shawn is a Colorado native, and began bowling at age 13 at the old Bowl-Aurora lanes. He has worked in pro shops for thirteen years, is a master driller, and is IBPSIA certified. Currently, Shawn is a Silver Certified coach, and working very hard towards Gold.<br /><br />Shawn has a long list of accomplishments as a competitive bowler. He has forty-one 300s (including one at the 2007 USBC nationals) and twenty-four 800s to his credit. Some of his highlights include: Bowler of the year (11-12), State USBC Scratch and all events Champion (13), State USBC Scratch Team Champion (14), Sights Unlimited Tips award (14).<br /><br />Shawn really enjoys helping bowlers get better and takes satisfaction in providing top-notch instruction as well as providing the proper layouts and equipment to his clients. He also relishes the smiles that bowlers get when they roll good scores and get what they want from the sport.",
        },
        {
            firstName: "Mary Ann",
            lastName: "Griffen",
            position: "Floor Manager",
        },
    ],
};

const Staff = () => {
    const classes = useStyles();

    return (
        <Container className={classes.fullPageContainer}>
            <PageBanner image={pageBanner} alt="Staff Banner" />
            <Container className={classes.mainContainer} maxWidth="lg">
                {pageData.announcement ? (
                    <AnnouncementCard
                        title={pageData.announcement.title}
                        text={pageData.announcement.text}
                    />
                ) : (
                    ""
                )}
                <TopCard text={pageData.bannerText} />
                <Container className={classes.serviceContainer}>
                    {pageData.services
                        ? pageData.services.map((item) => {
                              return (
                                  <ServiceCard
                                      image={item.image}
                                      title={`${item.firstName} ${item.lastName}`}
                                      subtitle={item.position}
                                      buttonValue="View Bio"
                                      detailTitle={`${item.firstName} ${item.lastName}'s Bio`}
                                      details={item.bio}
                                  />
                              );
                          })
                        : ""}
                </Container>
            </Container>
        </Container>
    );
};

const useStyles = makeStyles((theme) => ({
    announcementCard: { marginTop: 10, backgroundColor: "orange" },
    announcementContent: {
        paddingTop: theme.spacing(1),
    },
    annoucementHeader: {
        padding: theme.spacing(1),
    },
    announcementTitle: {
        fontSize: 20,
        fontWeight: "bold",
    },
    welcomeCard: { marginTop: 10 },
    serviceContainer: {
        display: "flex",
        justifyContent: "center",
        flexWrap: "wrap",
    },
    serviceCard: {
        width: 350,
        marginTop: 10,
        margin: 10,
        flexBasis: "auto",
    },
    cardMedia: {
        height: 175,
        width: "fit-content",
        margin: "auto",
    },
    cardTitle: {
        fontWeight: "bold",
        fontSize: 20,
        textAlign: "center",
    },
    cardSubtitle: {
        fontStyle: "italic",
        fontSize: 16,
        textAlign: "center",
    },
    cardActions: {
        display: "flex",
        justifyContent: "center",
    },
    fullPageContainer: {
        padding: 0,
        width: "100%",
        margin: "auto",
    },
    imageBanner: {
        height: "100%",
        marginLeft: "50%",
        transform: "translate(-50%, 0)",
    },
    imageBannerContainer: {
        width: "100%",
        height: 300,
        padding: 0,
        margin: 0,
        textAlign: "center",
        overflow: "hidden",
    },
}));

export default Staff;
