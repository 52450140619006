import React from "react";
import {
    Card,
    CardHeader,
    CardContent,
    Avatar,
} from "@material-ui/core";
import AnnouncementIcon from "@material-ui/icons/Announcement";
import { makeStyles } from "@material-ui/core/styles";
import ReactHtmlParser from "react-html-parser";

const AnnouncementCard = (props) => {
    const classes = useStyles();
    return (
        <Card className={classes.card}>
            <CardHeader
                avatar={
                    <Avatar>
                        <AnnouncementIcon />
                    </Avatar>
                }
                className={classes.header}
                title={props.title}
                titleTypographyProps={{
                    className: classes.title,
                }}
            />
            <CardContent className={classes.content}>
                {ReactHtmlParser(props.text)}
            </CardContent>
        </Card>
    );
};

const useStyles = makeStyles((theme) => ({
    card: { marginTop: 10, backgroundColor: "orange" },
    content: {
        paddingTop: theme.spacing(1),
    },
    header: {
        padding: theme.spacing(1),
    },
    title: {
        fontSize: 20,
        fontWeight: "bold",
    },
}));

export default AnnouncementCard;
