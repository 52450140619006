import {
	Button,
	Card,
	CardActions,
	CardContent,
	Typography,
	Container,
	CardMedia,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import tenbackLogo from "../images/tenback-logo.jpg";
import pageBanner from "../images/home-banner.jpg";
import React, { useEffect, useState } from "react";
import TopCard from "./../common/TopCard";
import AnnouncementCard from "../common/AnnouncementCard";
import PageBanner from "../common/PageBanner";
import FacebookPage from "./FacebookPage";
import { Link } from "react-router-dom";
import { getPageData } from "../api/PageDataAPI";

const Home = (props) => {
	const [pageData, setPageData] = useState({});
	const classes = useStyles();

	const retrievePageData = async () => {
		let response = await getPageData("home");
		setPageData(response);
	};

	useEffect(() => {
		retrievePageData();
	}, []);

	return (
		<Container className={classes.fullPageContainer}>
			<PageBanner image={pageBanner} alt="Home Banner" />
			<Container className={classes.mainContainer} maxWidth="lg">
            {pageData.announcement ? (
					<AnnouncementCard
						title={pageData.announcement.title}
						text={pageData.announcement.text}
					/>
				) : (
					""
				)}
				<TopCard text={pageData.bannerText} />
				<Container className={classes.pageContainer}>
					<Container className={classes.serviceContainer}>
						{pageData.cards ? pageData.cards.map((item) => (
							<Card className={classes.serviceCard}>
								<CardMedia
									className={classes.cardMedia}
									image={
										item.image ? item.image : tenbackLogo
									}
									title="TenBack Logo"
									component="img"
								/>
								<CardContent>
									<Typography className={classes.cardTitle}>
										{item.title}
									</Typography>
									<Typography>{item.details}</Typography>
								</CardContent>
								<CardActions className={classes.cardActions}>
									<Button
										size="small"
										color="primary"
										component={Link}
										to={{ pathname: item.link }}
										rel="noopener"
									>
										{item.linkText}
									</Button>
								</CardActions>
							</Card>
						)) : ""}
					</Container>
					<Container className={classes.facebookContainer}>
						<FacebookPage height={fb.height} width={fb.width} />
					</Container>
				</Container>
			</Container>
		</Container>
	);
};

const fb = {
	height: 1075,
	width: 350,
}; //values need to match FacebookPage src values. Src cannot be adjusted with JSX

const useStyles = makeStyles((theme) => ({
	announcementCard: { marginTop: 10, backgroundColor: "orange" },
	announcementContent: {
		paddingTop: theme.spacing(1),
	},
	annoucementHeader: {
		padding: theme.spacing(1),
	},
	announcementTitle: {
		fontSize: 20,
		fontWeight: "bold",
	},
	welcomeCard: { marginTop: 10 },
	serviceContainer: {
		display: "flex",
		justifyContent: "center",
		flexWrap: "wrap",
	},
	serviceCard: {
		width: 350,
		marginTop: 10,
		margin: 10,
	},
	cardMedia: {
		height: 175,
		width: "fit-content",
		margin: "auto",
	},
	cardTitle: {
		fontWeight: "bold",
		fontSize: 20,
	},
	cardActions: {
		display: "flex",
		justifyContent: "center",
	},
	fullPageContainer: {
		padding: 0,
		width: "100%",
		margin: "auto",
	},
	imageBanner: {
		height: "100%",
		marginLeft: "50%",
		transform: "translate(-50%, 0)",
	},
	imageBannerContainer: {
		width: "100%",
		height: 300,
		padding: 0,
		margin: 0,
		textAlign: "center",
		overflow: "hidden",
	},
	pageContainer: {
		display: "flex",
		flexDirection: "row",
		flexWrap: "none",
	},
	facebookContainer: {
		display: "flex",
		[theme.breakpoints.down("md")]: {
			display: "none",
		},
		justifyContent: "center",
		margin: 10,
		height: fb.height,
		width: fb.width,
	},
}));

export default Home;
