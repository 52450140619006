import { API_URL } from "./../config/api";

export const FILE_URL = `${API_URL}/file`;

export const uploadFile = async (fileData) => {
	let formData = new FormData();
	formData.append("file", fileData);
	let response = await fetch(`${FILE_URL}`, {
		method: "POST",
		body: formData,
		header: "multipart/form-data",
	});
	return await response.json();
};
