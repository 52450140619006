import React, { useState } from "react";
import {
    AppBar,
    Button,
    Divider,
    Drawer,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Toolbar,
    Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
import tenbackLogo from "../images/tenback-logo-wide-vector.png";
import { Link } from "react-router-dom";

const menuItems = [
    {
        name: "Home",
        link: "/",
    },
    {
        name: "Shop",
        link: "/shop",
    },
    {
        name: "Drilling",
        link: "/drilling",
    },
    {
        name: "Maintenance",
        link: "/maintenance",
    },
    {
        name: "Coaching",
        link: "/coaching",
    },
    {
        name: "Staff",
        link: "/staff",
    },
    {
        name: "Contact Us",
        link: "/contactus",
    },
];
const MainMenu = (props) => {
    const classes = useStyles();
    const [drawerOpen, setDrawerOpen] = useState(false);
    return (
        <div>
            <AppBar position="static" className={classes.appBar}>
                <Toolbar className={classes.mobileToolbar}>
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        onClick={() => setDrawerOpen(!drawerOpen)}
                        className={classes.menuIcon}
                    >
                        <MenuIcon />
                    </IconButton>
                    {/* <Container className={classes.pageTitle}> */}
                    <img
                        src={tenbackLogo}
                        className={classes.appBarImage}
                        alt="TenBack Bowling Outfitters"
                    />
                    {/* </Container> */}
                    <div></div>
                </Toolbar>
                <Toolbar className={classes.desktopToolbar}>
                    <img
                        src={tenbackLogo}
                        className={classes.appBarImage}
                        alt="TenBack Bowling Outfitters"
                    />
                    <Typography>Today: Open 10 am - 5 pm</Typography>
                    <div>
                        {menuItems.map((item) => (
                            <Button component={Link} to={item.link}>
                                {item.name}
                            </Button>
                        ))}
                    </div>
                </Toolbar>
            </AppBar>
            <Drawer
                anchor="top"
                open={drawerOpen}
                onClose={() => setDrawerOpen(false)}
            >
                <List>
                    <ListItem button key={0}>
                        <ListItemIcon onClick={() => setDrawerOpen(false)}>
                            <CloseIcon />
                        </ListItemIcon>
                        <ListItemText primary="Close" />
                    </ListItem>
                    <Divider />
                    {menuItems.map((item, index) => (
                        <ListItem
                            button
                            key={index}
                            component={Link}
                            to={item.link}
                            onClick={() => setDrawerOpen(false)}
                        >
                            <ListItemText primary={item.name} />
                        </ListItem>
                    ))}
                    <Divider />
                    <ListItem
                        button
                        component={Link}
                        to="/contactus"
                        key={99}
                        onClick={() => setDrawerOpen(false)}
                    >
                        <ListItemText primary={`Today: Open 10 am - 5 pm`} />
                    </ListItem>
                </List>
            </Drawer>
        </div>
    );
};

const appBarHeight = 64;

const useStyles = makeStyles((theme) => ({
    appBar: {
        maxHeight: appBarHeight,
        backgroundColor: "#fff",
        color: "#000",
    },
    appBarImage: {
        maxHeight: appBarHeight - 4,
        paddingTop: 2,
    },
    desktopToolbar: {
        display: "flex",
        [theme.breakpoints.down("md")]: {
            display: "none",
        },
        justifyContent: "space-between",
    },
    mobileToolbar: {
        display: "none",
        [theme.breakpoints.down("md")]: {
            display: "flex",
        },
        justifyContent: "space-between",
    },
}));

export default MainMenu;
