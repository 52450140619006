import { Button, Container, makeStyles } from '@material-ui/core'
import React, { useState } from 'react'
import { uploadFile } from '../api/FileAPI'


const Administration = () => {
    const classes = useStyles()
    
    const [selectedFile, setSelectedFile] = useState("")

    const handleSelectFile = (e) => {
        setSelectedFile(e.target.files[0])
    }

    const handleUploadFile = async () => {
        let response = await uploadFile(selectedFile)
        console.log(response)
        setSelectedFile("")
    }

    return (
        <Container className={classes.fullPageContainer}>
            <input type="file" onChange={handleSelectFile} />
            <Button onClick={handleUploadFile}>Upload</Button>
        </Container>
    )

}

const useStyles = makeStyles((theme) => ({
	fullPageContainer: {
		padding: 0,
		width: "100%",
		margin: "auto",
	},

}))


export default Administration