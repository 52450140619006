import React from "react";
import MainMenu from "./common/MainMenu";
import Home from "./Home/Home";
import Staff from "./Staff/Staff";
import { makeStyles } from "@material-ui/core/styles";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Footer from "./common/Footer";
import Maintenance from "./Maintenance/Maintenance";
import Drilling from "./Drilling/Drilling";
import Coaching from "./Coaching/Coaching";
import ContactUs from "./ContactUs/ContactUs";
import Shop from "./Shop/Shop";
import ScrollToTop from "./functions/ScrollToTop";
import Administration from "./Admin/Administration";

const App = () => {
    const classes = useStyles();
    return (
        <BrowserRouter>
            <ScrollToTop>
            <div className={classes.app}>
                <MainMenu />
                <Switch>
                    <Route path="/administration">
                        <Administration />
                    </Route>
                    <Route path="/shop">
                        <Shop />
                    </Route>
                    <Route path="/contactus">
                        <ContactUs />
                    </Route>
                    <Route path="/coaching">
                        <Coaching />
                    </Route>
                    <Route path="/drilling">
                        <Drilling />
                    </Route>
                    <Route path="/maintenance">
                        <Maintenance />
                    </Route>
                    <Route path="/staff">
                        <Staff />
                    </Route>
                    <Route path="/">
                        <Home />
                    </Route>
                </Switch>
                <Footer />
            </div>
            </ScrollToTop>
        </BrowserRouter>
    );
};

const useStyles = makeStyles((theme) => ({
    app: {
        backgroundColor: "#ddd",
    },
}));

export default App;
