import React, { useState } from "react";
import {
    Card,
    CardMedia,
    CardContent,
    CardActions,
    Typography,
    Button,
    Dialog,
    DialogActions,
    DialogTitle,
    DialogContent,
    Container,
    Grid,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ReactHtmlParser from "react-html-parser";
import { formatUS } from "./../../functions/currency";
import ReactPlayer from "react-player";
import { useHistory, Link } from "react-router-dom";
import moment from "moment";
import clsx from "clsx";

const ServiceCard = (props) => {
    let history = useHistory;
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [videoOpen, setVideoOpen] = useState(false);

    const toggleOpen = () => {
        setOpen(!open);
    };

    const toggleVideoOpen = () => {
        setVideoOpen(!videoOpen);
    };

    return (
        <Card className={classes.card}>
            {props.image ? (
                <CardMedia
                    className={classes.image}
                    src={props.image}
                    title={props.imageTitle}
                    component="img"
                />
            ) : (
                ""
            )}
            <CardContent>
                {moment(props.releaseDate).isAfter() ? (
                    <Container className={classes.releaseDateContainer}>
                        <Typography
                            color="primary"
                            className={classes.releaseDate}
                        >
                            Release:{" "}
                            {moment(props.releaseDate).format("MMMM DD")}
                        </Typography>
                    </Container>
                ) : (
                    ""
                )}
                <Typography className={classes.title}>{props.title}</Typography>
                <Typography className={classes.subtitle}>
                    {props.subtitle}
                </Typography>
                {props.price ? (
                    props.salePrice ? (
                        <Container className={classes.priceContainer}>
                            <Typography className={classes.salePrice}>
                                Sale! {formatUS(props.salePrice)}
                            </Typography>
                            <Typography className={classes.priceCrossedOut}>
                                {formatUS(props.price)}
                            </Typography>
                        </Container>
                    ) : (
                        <Container className={classes.priceContainer}>
                            <Typography className={classes.price}>
                                {formatUS(props.price)}
                            </Typography>
                        </Container>
                    )
                ) : (
                    ""
                )}
                {props.priceOverride ? (
                    <Container className={classes.priceContainer}>
                        <Typography className={classes.price}>
                            {ReactHtmlParser(props.priceOverride)}
                        </Typography>
                    </Container>
                ) : (
                    ""
                )}
            </CardContent>
            {props.details ? (
                <div>
                    <CardActions className={classes.actions}>
                        <Button
                            size="small"
                            color="primary"
                            onClick={() => toggleOpen()}
                        >
                            {props.buttonValue
                                ? props.buttonValue
                                : "View Details"}
                        </Button>
                        {props.orderable ? (
                            <Button
                                size="small"
                                color="secondary"
                                onClick={() => toggleOpen()}
                            >
                                {moment(props.releaseDate).isBefore()
                                    ? "Order Now"
                                    : "Reserve Now"}
                            </Button>
                        ) : (
                            ""
                        )}
                    </CardActions>
                    <Dialog open={open} onClose={toggleOpen}>
                        <DialogTitle>{props.detailTitle}</DialogTitle>
                        <DialogContent>
                            <Grid container>
                                <Grid item xs={12} md={6}>
                                    {props.image ? (
                                        <CardMedia
                                            className={classes.image}
                                            src={props.image}
                                            title={props.imageTitle}
                                            component="img"
                                        />
                                    ) : (
                                        ""
                                    )}

                                    {props.price ? (
                                        props.salePrice ? (
                                            <Container
                                                className={
                                                    classes.priceContainer
                                                }
                                            >
                                                <Typography
                                                    className={
                                                        classes.salePrice
                                                    }
                                                >
                                                    Sale!{" "}
                                                    {formatUS(props.salePrice)}
                                                </Typography>
                                                <Typography
                                                    className={
                                                        classes.priceCrossedOut
                                                    }
                                                >
                                                    {formatUS(props.price)}
                                                </Typography>
                                            </Container>
                                        ) : (
                                            <Container
                                                className={
                                                    classes.priceContainer
                                                }
                                            >
                                                <Typography
                                                    className={classes.price}
                                                >
                                                    {formatUS(props.price)}
                                                </Typography>
                                            </Container>
                                        )
                                    ) : (
                                        ""
                                    )}
                                    {props.priceOverride ? (
                                        <Container
                                            className={classes.priceContainer}
                                        >
                                            <Typography
                                                className={classes.price}
                                            >
                                                {ReactHtmlParser(
                                                    props.priceOverride
                                                )}
                                            </Typography>
                                        </Container>
                                    ) : (
                                        ""
                                    )}
                                    <Container
                                        className={classes.buttonContainer}
                                    >
                                        {props.videoLink ? (
                                            <Button
                                                size="small"
                                                color="primary"
                                                variant="contained"
                                                onClick={toggleVideoOpen}
                                                className={classes.linkButtons}
                                            >
                                                Video Review
                                            </Button>
                                        ) : (
                                            ""
                                        )}
                                        {props.mfrLink ? (
                                            <Button
                                                size="small"
                                                color="primary"
                                                variant="contained"
                                                // onClick={openMfrLink}
                                                className={classes.linkButtons}
                                                component={Link}
                                                to={{ pathname: props.mfrLink }}
                                                target="_blank"
                                                rel="noopener"
                                            >
                                                {props.subtitle}'s website
                                            </Button>
                                        ) : (
                                            ""
                                        )}
                                        {props.orderable ? (
                                            <Button
                                                size="small"
                                                color="secondary"
                                                variant="contained"
                                                className={clsx(
                                                    classes.linkButtons,
                                                    classes.orderButton
                                                )}
                                            >
                                                {moment(
                                                    props.releaseDate
                                                ).isBefore()
                                                    ? "Order Now"
                                                    : "Reserve Yours"}
                                            </Button>
                                        ) : (
                                            ""
                                        )}
                                    </Container>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    {ReactHtmlParser(props.details)}
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                size="small"
                                variant="contained"
                                onClick={() => toggleOpen()}
                            >
                                Close
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog
                        open={videoOpen}
                        onClose={toggleVideoOpen}
                        fullScreen={true}
                    >
                        {/* <Container > */}
                        <ReactPlayer
                            url={props.videoLink}
                            height="100%"
                            width="100%"
                        />
                        {/* </Container> */}
                    </Dialog>
                </div>
            ) : (
                ""
            )}
        </Card>
    );
};

const useStyles = makeStyles((theme) => ({
    card: {
        width: 280,
        margin: 10,
        [theme.breakpoints.down("sm")]: { margin: 0, marginTop: 10 },
        [theme.breakpoints.down("md")]: { margin: 10, marginTop: 10 },
        marginTop: 10,
        flexBasis: "auto",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
    },
    image: {
        height: 175,
        width: "fit-content",
        margin: "auto",
    },
    title: {
        fontWeight: "bold",
        fontSize: 20,
        textAlign: "center",
    },
    subtitle: {
        fontStyle: "italic",
        fontSize: 16,
        textAlign: "center",
    },
    actions: {
        display: "flex",
        justifyContent: "center",
    },
    priceContainer: {
        display: "flex",
        justifyContent: "space-around",
        alignItems: "center",
        textAlign: "center",
    },
    priceCrossedOut: {
        textDecoration: "line-through",
        textAlign: "center",
    },
    salePrice: {
        color: "red",
        fontWeight: "bold",
        textAlign: "center",
        paddingRight: 20,
    },
    buttonContainer: {
        display: "flex",
        justifyContent: "center",
        flexDirection: "row",
        wrap: "wrap",
    },
    linkButtons: {
        margin: 5,
        textAlign: "center",
    },
    orderButton: {
        // backgroundColor: theme.palette.success.main
    },
    releaseDate: {
        fontWeight: "bold",
    },
}));

export default ServiceCard;
