import React, { useEffect, useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
	Container,
	Grid,
	AccordionSummary,
	Typography,
	FormControl,
	FormControlLabel,
	RadioGroup,
	Radio,
	Button,
	Dialog,
	DialogActions,
	Select,
	InputLabel,
	Box,
} from "@material-ui/core";
import pageBanner from "../images/shop-banner.jpg";
import PageBanner from "./../common/PageBanner";
import AnnouncementCard from "./../common/AnnouncementCard";
import TopCard from "./../common/TopCard";
import ItemCard from "./Items/ItemCard";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import { getCategories } from "../api/CategoryAPI";
import { getAllShopItems } from "../api/ShopItemAPI";
import TenBackLogo from "../images/tenback-logo.jpg";
import { getPageData } from "../api/PageDataAPI";

const categoryLoading = [
	{
		_id: "abcdefghijklmnop12345678",
		name: "Loading...",
		brands: [],
	},
];

const itemsLoading = [
	{
		type: "60fda844147194e5613e8b1f",
		brand: {
			_id: "60fda8f3d5c9c733f6692215",
			name: "Loading...",
		},
		name: "Please Wait",
		image: { TenBackLogo },
		price: "0.00",
		salePrice: null,
	},
];

const Shop = () => {
	const classes = useStyles();
	const [shopItems, setShopItems] = useState(itemsLoading);
	const [categoryList, setCategoryList] = useState(categoryLoading);
	const [results, setResults] = useState([]);
	const [filterText, setFilterText] = useState("");
	const [brandSelected, setBrandSelected] = useState("");
	const [categorySelected, setCategorySelected] = useState("");
	const [brandState, setBrandState] = useState({});
	const [navDialogOpen, setNavDialogOpen] = useState(false);
	const [pageData, setPageData] = useState({});
	const [sortItems, setSortItems] = useState("releaseDate");

	const getCategoryList = async () => {
		let response = await getCategories();
		console.log(response);
		setCategoryList(response);
	};

	const getShopItems = async () => {
		let response = await getAllShopItems();
		setShopItems(response);
	};

	const retrievePageData = async () => {
		let response = await getPageData("shop");
		setPageData(response);
	};

	useEffect(() => {
		getCategoryList();
		getShopItems();
		retrievePageData();
	}, []);

	const updateResults = () => {
		let filteredItems = shopItems;
		filteredItems = filteredItems.filter((item) =>
			brandSelected ? brandSelected === item.brand._id : item
		);
		filteredItems = filteredItems.filter((item) =>
			categorySelected ? categorySelected === item.category._id : item
		);
		filteredItems = filteredItems.filter((item) =>
			filterText
				? item.name.toLowerCase().includes(filterText.toLowerCase())
				: item
		);
		let sortedItems = [];
		if (sortItems === "releaseDate") {
			sortedItems = filteredItems.sort((a, b) => {
				if (a.releaseDate < b.releaseDate) {
					return -1;
				} else {
					return 1;
				}
			});
		} else if (sortItems === "name") {
			sortedItems = filteredItems.name.sort((a, b) => {
				if (a.name < b.name) {
					return -1;
				} else {
					return 1;
				}
			});
		}
		setResults(sortedItems);
	};

	const updateFilter = async (typeId, event) => {
		console.log(typeId);
		console.log(event.target.name);
		if (typeId !== categorySelected) {
			setCategorySelected(typeId);
			setBrandState({ [event.target.id]: event.target.checked });
		} else {
			setBrandState({
				...brandState,
				[event.target.id]: event.target.checked,
			});
		}
	};

	const updateSort = (e) => {
		setSortItems(e.target.value);
	};

	const updateBrand = (e) => {
		setBrandSelected(e.target.value);
	};

	const updateCategory = (typeId) => (event, isExpanded) => {
		setCategorySelected(isExpanded ? typeId : false);
		setBrandSelected("");
	};

	const toggleNavDialog = () => {
		setNavDialogOpen(!navDialogOpen);
	};

	useEffect(() => {
		updateResults();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filterText, brandSelected, categorySelected, shopItems, sortItems]);

	useEffect(() => {
		console.log(brandSelected);
	}, [brandSelected]);

	const filterAccordion = (
		<>
        <Typography variant="h5">
            Filter
        </Typography>
			<Accordion
				id="accordion-show-all"
				expanded={categorySelected === "" || categorySelected === false}
				onChange={updateCategory("")}
                style={{ marginTop: 2}}
			>
				<AccordionSummary>
					<Typography>Show All</Typography>
				</AccordionSummary>
			</Accordion>
			{categoryList.map((category, index) => (
				<Accordion
					id={category._id}
					expanded={categorySelected === category._id}
					onChange={updateCategory(category._id)}
				>
					<AccordionSummary
						// expandIcon={<ExpandMoreIcon />}
						aria-controls={`panel-${index}-content`}
						id={`panel-${index}-header`}
					>
						<Typography>{category.name}</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<FormControl component="fieldset">
							<RadioGroup
								aria-label={category.name}
								name={`radio-${category.name}`}
								id={category._id}
								value={brandSelected}
								onChange={updateBrand}
							>
								<FormControlLabel
									value=""
									label="Show All"
									control={<Radio />}
								/>
								{category.brands
									.sort((a, b) => {
										if (a.name < b.name) {
											return -1;
										} else {
											return 1;
										}
									})
									.map((brand) => (
										<FormControlLabel
											value={brand._id}
											label={brand.name}
											control={<Radio />}
										/>
									))}
							</RadioGroup>
						</FormControl>
					</AccordionDetails>
				</Accordion>
			))}
		</>
	);

	const sortAccordion = (
		<>
			<Typography variant="h5" style={{ marginTop: 10 }}>
				Sort
			</Typography>
			<Accordion
				id="accordion-sort-all"
				style={{ marginTop: 2 }}
				expanded={true}
			>
				<AccordionDetails>
					<FormControl component="fieldset">
						<RadioGroup
							aria-label={"Sort Items"}
							name={`radio-sort`}
							id={`radiogroup-sort`}
							value={sortItems}
							onChange={updateSort}
						>
							<FormControlLabel
								value={"releaseDate"}
								label={"Release Date"}
								control={<Radio />}
							/>
							<FormControlLabel
								value={"name"}
								label={"Name"}
								control={<Radio />}
							/>
						</RadioGroup>
					</FormControl>
				</AccordionDetails>
			</Accordion>
		</>
	);

	return (
		<Container className={classes.fullPageContainer}>
			<PageBanner image={pageBanner} alt="Store Banner" />
			<Container className={classes.mainContainer} maxWidth="lg">
				{pageData.announcement ? (
					<AnnouncementCard
						title={pageData.announcement.title}
						text={pageData.announcement.text}
					/>
				) : (
					""
				)}
				{pageData.bannerText ? (
					<TopCard text={pageData.bannerText} />
				) : (
					""
				)}
				<Container className={classes.serviceContainer}>
					<Grid container>
						<Grid item xs={12} md={3}>
							<Container className={classes.shopNav}>
								<Box>{filterAccordion}</Box>
								<Box>{sortAccordion}</Box>
							</Container>
							<Container className={classes.shopNavMoblie}>
								<Button
									variant="contained"
									color="primary"
									className={classes.mobileNavButton}
									onClick={toggleNavDialog}
								>
									Filter/Sort
								</Button>
								<Dialog
									open={navDialogOpen}
									onClose={toggleNavDialog}
									fullScreen
									className={classes.navDialog}
								>
									<Container
										className={
											classes.dialogAccordionContainer
										}
									>
										<Box>{filterAccordion}</Box>
										<Box>{sortAccordion}</Box>
									</Container>
									<DialogActions
										className={classes.dialogActions}
									>
										<Button
											size="small"
											variant="contained"
											onClick={toggleNavDialog}
										>
											Update Filter/Sort
										</Button>
									</DialogActions>
								</Dialog>
							</Container>
						</Grid>
						<Grid item xs={12} md={9}>
							<Container className={classes.itemList}>
								{results.length > 0 ? (
									results
										.sort((a, b) => {
											if (a.name < b.name) {
												return -1;
											} else {
												return 1;
											}
										})
										.map((item) => (
											<ItemCard
												image={item.picture}
												title={item.name}
												subtitle={item.brand.name}
												price={item.price}
												salePrice={item.salePrice}
												priceOverride={
													item.priceOverride
												}
												detailTitle={`${item.name} from ${item.brand.name}`}
												details={item.description}
												mfrLink={item.website}
												videoLink={item.video}
												releaseDate={item.releaseDate}
												orderable={item.orderable}
											/>
										))
								) : (
									<Typography className={classes.noResult}>
										No results. Please update filters.
									</Typography>
								)}
							</Container>
						</Grid>
					</Grid>
				</Container>
			</Container>
		</Container>
	);
};

const useStyles = makeStyles((theme) => ({
	serviceContainer: {
		display: "flex",
		justifyContent: "center",
		flexWrap: "wrap",
		padding: 0,
	},
	shopNav: {
		padding: 0,
		paddingTop: 10,
		paddingBottom: 10,
		[theme.breakpoints.down("sm")]: { display: "none" },
	},
	shopNavMoblie: {
		display: "none",
		[theme.breakpoints.down("sm")]: { display: "block" },
		paddingTop: 10,
		textAlign: "center",
	},
	mobileNavButton: {
		width: 280,
	},
	fullPageContainer: {
		padding: 0,
		width: "100%",
		margin: "auto",
	},
	itemList: {
		display: "flex",
		flexDirecton: "row",
		flexWrap: "wrap",
		justifyContent: "center",
		padding: 0,
		paddingLeft: theme.spacing(2),
		[theme.breakpoints.down("sm")]: { paddingLeft: 0 },
	},
	selectedAccordion: {
		backgroundColor: "#777",
	},
	mainContainer: {
		minHeight: 700,
	},
	dialogAccordionContainer: {
		paddingTop: 20,
		paddingBottom: 50,
	},
	dialogActions: {
		position: "fixed",
		bottom: 0,
		right: 0,
		width: "100%",
		backgroundColor: "#fff",
	},
	noResult: {
		paddingTop: 20,
		fontWeight: "bold",
	},
}));

const Accordion = withStyles((theme) => ({
	root: {
		// border: "1px solid rgba(0, 0, 0, .25)",
		// boxShadow: "none",
		// "&:not(:last-child)": {
		//     borderBottom: 0,
		// },
		// "&:before": {
		//     display: "none",
		// },
		"&$expanded": {
			margin: "auto",
		},
		"& .Mui-expanded": {
			backgroundColor: theme.palette.primary.main,
			color: "#fff",
			borderTopLeftRadius: 4,
			borderTopRightRadius: 4,
		},
	},
	expanded: {},
}))(MuiAccordion);

const AccordionDetails = withStyles((theme) => ({
	root: {
		backgroundColor: "#eee",
		border: "1px solid #333",
	},
}))(MuiAccordionDetails);

export default Shop;
