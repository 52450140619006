import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
    Container,
    Typography,
    Card,
    CardMedia,
    CardContent,
    CardActions,
    Button,
    Table,
    TableBody,
    TableRow,
    TableCell,
} from "@material-ui/core";
import pageBanner from "../images/contact-banner.jpg";
import PageBanner from "./../common/PageBanner";
import AnnouncementCard from "./../common/AnnouncementCard";
import TopCard from "./../common/TopCard";
import ReactHtmlParser from "react-html-parser";
import { Link } from 'react-router-dom'

const pageData = {
    bannerText:
        "Your business is really important to us! Please come visit us or call us during business hours. You can also call and leave a message after hours and we'll get back to you as soon as possible! If you would like to send us an email message, please use the address below and we'll get back to you shortly!",
    cards: [
        {
            title: "Hours",
            hours: [
                {
                    day: "Sunday",
                    closed: true,
                },
                {
                    day: "Monday",
                    closed: false,
                    open: "10:00",
                    close: "8:00",
                },
                {
                    day: "Tuesday",
                    closed: false,
                    open: "10:00",
                    close: "8:00",
                },
                {
                    day: "Wednesday",
                    closed: false,
                    open: "10:00",
                    close: "8:00",
                },
                {
                    day: "Thursday",
                    closed: false,
                    open: "10:00",
                    close: "8:00",
                },
                {
                    day: "Friday",
                    closed: false,
                    open: "10:00",
                    close: "8:00",
                },
                {
                    day: "Saturday",
                    closed: false,
                    open: "10:00",
                    close: "7:00",
                },
            ],
        },
        {
            title: "Address",
            description:
                "6767 Leetsdale Dr, Denver CO<br />Attached to Bowlero Cherry Creek",
            link: "https://goo.gl/maps/6AoKH3aqLR9HqCpu9",
            linkText: "Get Directions",
        },
        {
            title: "Phone Number",
            description: "720-379-6492",
        },
        {
            title: "Email",
            description: "tenbackbowlingoutfitters@gmail.com",
            link: "mailto:tenbackbowlingoutfitters@gmail.com",
            linkText: "Email Us",
        },
    ],
};

const ContactUs = () => {
    const classes = useStyles();
    return (
        <Container className={classes.fullPageContainer}>
            <PageBanner image={pageBanner} alt="Contact Us Banner" />
            <Container className={classes.mainContainer} maxWidth="lg">
                {pageData.announcement ? (
                    <AnnouncementCard
                        title={pageData.announcement.title}
                        text={pageData.announcement.text}
                    />
                ) : (
                    ""
                )}
                <TopCard text={pageData.bannerText} />
                <Container className={classes.serviceContainer}>
                    {pageData.cards.map((item) => (
                        <Card className={classes.serviceCard} id={item.title}>
                            {item.image ? (
                                <CardMedia
                                    className={classes.cardMedia}
                                    image={item.image}
                                    title={item.imageTitle}
                                    component="img"
                                />
                            ) : (
                                ""
                            )}
                            <CardContent>
                                <Typography className={classes.cardTitle}>
                                    {item.title}
                                </Typography>
                                <Typography>
                                    {ReactHtmlParser(item.description)}
                                </Typography>
                                {item.hours ? (
                                    <Table>
                                        <TableBody>
                                            {item.hours.map((day) => (
                                                <TableRow key={day.day}>
                                                    <TableCell
                                                        component="th"
                                                        scope="row"
                                                    >
                                                        {day.day}
                                                    </TableCell>
                                                    <TableCell>
                                                        {day.closed
                                                            ? "Closed"
                                                            : `${day.open} - ${day.close}`}
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                ) : (
                                    ""
                                )}
                            </CardContent>
                            <CardActions className={classes.cardActions}>
                                <Button size="small" color="primary" component={Link}
                                                to={{ pathname: item.link }}
                                                target="_blank"
                                                rel="noopener">
                                    {item.linkText}
                                </Button>
                            </CardActions>
                        </Card>
                    ))}
                </Container>
            </Container>
        </Container>
    );
};

const useStyles = makeStyles((theme) => ({
    serviceContainer: {
        display: "flex",
        justifyContent: "center",
        flexWrap: "wrap",
    },
    serviceCard: {
        width: 350,
        marginTop: 10,
        margin: 10,

        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
    },
    cardMedia: {
        height: 175,
        width: "fit-content",
        margin: "auto",
    },
    cardTitle: {
        fontWeight: "bold",
        fontSize: 20,
        textAlign: "center",
    },
    cardActions: {
        display: "flex",
        justifyContent: "center",
    },
    fullPageContainer: {
        padding: 0,
        width: "100%",
        margin: "auto",
    },
}));

export default ContactUs;
