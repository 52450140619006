import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
    Container,
    Divider,
    List,
    ListItem,
    ListItemText,
    Typography,
} from "@material-ui/core";
import { Link } from "react-router-dom";

const listTenBack = [
    {
        name: "Home",
        link: "/",
    },
    {
        name: "Shop",
        link: "/shop",
    },
    {
        name: "Drilling",
        link: "/drilling",
    },
    {
        name: "Maintenance",
        link: "/maintenance",
    },
    {
        name: "Coaching",
        link: "/coaching",
    },
    {
        name: "Staff",
        link: "/staff",
    },
    {
        name: "Contact Us",
        link: "/contactus",
    },
    {
        name: "Administration",
        link: "/administration",
    },
];

const listSocial = [
    {
        name: "Facebook",
        link: "https://www.facebook.com/TenbackBowlingOutfitters",
    },
    {
        name: "YouTube",
        link: "https://www.youtube.com/channel/UCujaCb3k1AsagYLjGuKj3_g",
    },
];

const listAffiliations = [
    {
        name: "900 Global",
        link: "https://www.900global.com/",
    },
    {
        name: "Dexter",
        link: "https://www.dexterbowling.com/",
    },
    {
        name: "Ebonite",
        link: "http://www.ebonite.com/",
    },
    {
        name: "Hammer",
        link: "https://hammerbowling.com/",
    },
    {
        name: "Roto Grip",
        link: "https://www.stormbowling.com/roto-grip-balls",
    },
    {
        name: "Storm",
        link: "https://www.stormbowling.com/",
    },
    {
        name: "Track",
        link: "http://www.trackbowling.com/",
    },
    {
        name: "Turbo",
        link: "https://turbogrips.com/",
    },
    {
        name: "Vise",
        link: "https://www.viseinserts.com/",
    },
];

const Footer = (props) => {
    const classes = useStyles();
    return (
        <div className={classes.footerContainer}>
            <Container className={classes.footerList}>
                <Typography>TenBack</Typography>
                <Divider className={classes.footerDivider} />
                <List dense={true}>
                    {listTenBack.map((item) => (
                        <ListItem
                            button
                            key={item.name}
                            component={Link}
                            to={item.link}
                        >
                            <ListItemText primary={item.name} />
                        </ListItem>
                    ))}
                </List>
            </Container>
            <Container className={classes.footerList}>
                <Typography>Social Media</Typography>
                <Divider className={classes.footerDivider} />
                <List dense={true}>
                    {listSocial.map((item) => (
                        <ListItem
                            button
                            key={item.name}
                            component={Link}
                            to={{pathname: item.link}}
                            target="_blank"
                            rel="noopener"
                        >
                            <ListItemText primary={item.name} />
                        </ListItem>
                    ))}
                </List>
            </Container>
            <Container className={classes.footerList}>
                <Typography>Affiliations</Typography>
                <Divider className={classes.footerDivider} />
                
                <List dense={true}>
                    {listAffiliations.map((item) => (
                        <ListItem
                            button
                            key={item.name}
                            component={Link}
                            to={{pathname: item.link}}
                            target="_blank"
                            rel="noopener"
                        >
                            <ListItemText primary={item.name} />
                        </ListItem>
                    ))}
                </List>
            </Container>
        </div>
    );
};

const useStyles = makeStyles((theme) => ({
    footerContainer: {
        margin: 0,
        padding: 0,
        width: "100%",
        backgroundColor: "#000",
        display: "flex",
        justifyContent: "center",
        flexWrap: "wrap",
        color: "#fff",
    },
    footerDivider: {
        backgroundColor: "#FFF",
    },
    footerList: {
        width: "30%",
        margin: 10,
        [theme.breakpoints.down("sm")]: { width: "95%" },
    },
}));

export default Footer;
