import React, { useState, useEffect } from "react";
import { Container } from "@material-ui/core";
import TopCard from "../common/TopCard";
import ServiceCard from "../common/ServiceCard";
import { makeStyles } from "@material-ui/core/styles";
import pageBanner from "../images/maintenance-banner.jpg";
import PageBanner from "../common/PageBanner";
import { getPageData } from "../api/PageDataAPI";
import AnnouncementCard from "./../common/AnnouncementCard";

const Maintenance = (props) => {
	const [pageData, setPageData] = useState({});
	const classes = useStyles();

	const retrievePageData = async () => {
		let response = await getPageData("maintenance");
		setPageData(response);
	};

	useEffect(() => {
		retrievePageData();
	}, []);

	return (
		<Container className={classes.fullPageContainer}>
			<PageBanner image={pageBanner} alt="Maintenance Banner" />
			<Container className={classes.mainContainer} maxWidth="lg">
				{pageData.announcement ? (
					<AnnouncementCard
						title={pageData.announcement.title}
						text={pageData.announcement.text}
					/>
				) : (
					""
				)}
				<TopCard text={pageData.bannerText} />
				<Container className={classes.serviceContainer}>
					{pageData.cards
						? pageData.cards.map((item) => {
								return (
									<ServiceCard
										image={item.image}
										title={item.title}
										price={item.price}
										salePrice={item.salePrice}
										priceOverride={item.priceOverride}
										detailTitle={item.title}
										details={item.details}
									/>
								);
						  })
						: "Please Wait..."}
				</Container>
			</Container>
		</Container>
	);
};

const useStyles = makeStyles((theme) => ({
	serviceContainer: {
		display: "flex",
		justifyContent: "center",
		flexWrap: "wrap",
	},
	fullPageContainer: {
		padding: 0,
		width: "100%",
		margin: "auto",
	},
	imageBanner: {
		height: "100%",
		marginLeft: "50%",
		transform: "translate(-50%, 0)",
	},
	imageBannerContainer: {
		width: "100%",
		height: 300,
		padding: 0,
		margin: 0,
		textAlign: "center",
		overflow: "hidden",
	},
}));

export default Maintenance;
