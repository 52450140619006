import React from "react";
import { Card, CardContent } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ReactHtmlParser from "react-html-parser";

const TopCard = (props) => {
    const classes = useStyles();
    return (
        <Card className={classes.card}>
            <CardContent>
                {ReactHtmlParser(props.text)}
            </CardContent>
        </Card>
    );
};

const useStyles = makeStyles((theme) => ({
    card: { marginTop: 10 },
}));

export default TopCard;
